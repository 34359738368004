<template>
    <div class="home-page">
        <Header/>
        <div class="banner">
            <img src="../assets/jinengdasai/banner.png">
        </div>
        <div class="main-page">
            <div class="jn-title">
                <div class="top-txt">赛事介绍</div>
                <div class="sub-txt">
                    Competition Introduction
                    <span/>
                </div>
            </div>
            <p class="desc">
                         大数据财务分析技能赛项是在仿真的企业环境中模拟企业通过财务数据分析驱动企业全面决策的全过程，由运营团队共同完成企业财务数据采集、处理、分析、挖掘、数据报告制作和报告解读的全过程，涉及职业岗位面较广。这些数字化财务人才日益成为当今企业迫切需要招录的财务人员。
大数据财务分析技能赛项参赛选手为职业院校普遍开设的财经商贸类专业的学生，参赛范围广。
            </p>
            <!-- <div class="more z-pointer" @click="$router.push('/desc')">【更多>>>】</div> -->
            <div class="flex-list">
                <div class="item" @click="$router.push('/desc?type=0')">
                    <img src="../assets/jinengdasai/tc1.png">
                    竞赛内容
                </div>
                <div class="item" @click="$router.push('/desc?type=1')">
                    <img src="../assets/jinengdasai/tc5.png">
                    参赛事宜
                </div>
                <div class="item" @click="$router.push('/desc?type=2')">
                    <img src="../assets/jinengdasai/tc4.png">
                    竞赛流程
                </div>
                <div class="item" @click="$router.push('/desc?type=3')">
                    <img src="../assets/jinengdasai/tc3.png">
                    评分标准
                </div>
                <div class="item" @click="$router.push('/desc?type=4')">
                    <img src="../assets/jinengdasai/tc2.png">
                    奖项设置
                </div>
            </div>


             <div class="jn-title">
                <div class="top-txt">大赛动态</div>
                <div class="sub-txt">
                    Competition News
                    <span/>
                </div>
            </div>
            <div class="news-block">
                <div class="blo">
                    <div class="title">
                        通知
                        <span class="z-pointer" @click="$router.push('/infoList?type=2')">更多>></span>
                    </div>
                    <div v-for="(item,index) in notificationList" :key="index" class="news-item z-pointer" @click="$router.push('/infoDetail?type=2&id='+item.id)">
                        <div :title="item.title" class="only-show-one">{{item.title}}</div>
                        <div class="time">{{item.create_time}}</div>
                        <div v-if="(Number(item.is_end) === 1)" class="is-end">已结束</div>
                        <div v-if="(Number(item.is_end) === 0)" class="is-end" style="background:red">进行中</div>
                    </div>
                </div>
                <div class="blo">
                    <div class="title">
                        新闻
                        <span class="z-pointer" @click="$router.push('/infoList?type=3')">更多>></span>
                    </div>
                    <div v-for="(item,index) in newsList" :key="index" class="news-item z-pointer" @click="$router.push('/infoDetail?type=3&id='+item.id)">
                        <div :title="item.title" class="only-show-one">{{item.title}}</div>
                        <div class="time">{{item.create_time}}</div>
                    </div>
                </div>
            </div>

            <div class="jn-title">
                <div class="top-txt">技能大赛平台</div>
                <div class="sub-txt">
                    Skills Competition Platform
                    <span/>
                </div>
            </div>
            <div class="flex">
                <div style="background:#51C58E" class="img-item">大赛在线提交系统</div>
                <div style="background:#38B6D5" class="img-item">教师/学生大赛练习系统</div>
            </div>
            <div class="flex">
                <div class="img-block" @click="$router.push('/onlineEnroll')">
                    <img class="imgc" src="../assets/jinengdasai/img1.png">
                    <div class="img-txt">在线报名系统</div>
                </div>
                <div class="img-block" @click="$router.push('/reportPPT')">
                    <img class="imgc" src="../assets/jinengdasai/img2.png">
                    <div class="img-txt">汇报PPT/视频提交系统</div>
                </div>
                <div class="img-block">
                    <img class="imgc" src="../assets/jinengdasai/img3.png">
                    <div class="img-txt">指导老师登录入口</div>
                </div>
                <div class="img-block">
                    <img class="imgc" src="../assets/jinengdasai/img4.png">
                    <div class="img-txt">参赛队员登录入口</div>
                </div>
            </div>

            <div class="flex">
                <div class="d-bl">
                    帮助&文件下载
                    <img src="../assets/jinengdasai/img5.png">
                </div>
                <div class="d-bl">
                    服务支持
                    <img src="../assets/jinengdasai/img6.png">
                </div>
            </div>

            <div class="flex">
                <div class="l-bl">
                    <div v-for="(item, index) in downloadList" :key="index" :class="index===0?'item bl-b':'item bl-b no-first'">
                        <span>•&nbsp;&nbsp;{{item.name}}</span>
                        <a :href="item.image" download target="_blank"><img src="../assets/jinengdasai/down.png"></a>
                    </div>
                    <!-- <div class="item">
                        <span>•&nbsp;&nbsp;技能大赛赛想说明下载</span>
                        <img src="../assets/jinengdasai/down.png">
                    </div>
                    <div class="item">
                        <span>•&nbsp;&nbsp;大赛指定训练平台要求下载</span>
                        <img src="../assets/jinengdasai/down.png">
                    </div> -->
                    
                </div>
                <div class="l-bl">
                    <div class="bl-b">
                        <div style="font-weight:bold" class="item">
                             赛事服务：
                        </div>
                        <div class="item" style="margin-top:1px">
                            贾老师 18701099332  jiaxiaohang@shouguan.cn
                        </div>
                    </div>
                    
                    <div class="bl-b no-first">
                        <div style="font-weight:bold" class="item">
                            技术支持：
                        </div>
                        <div class="item" style="margin-top:1px">
                            齐老师 18001317230  qiguoyi@shouguanedu.cn
                        </div>
                    </div>
                    <div class="bl-b no-first">
                        <div style="font-weight:bold" class="item">
                            投诉建议：
                        </div>
                        <div class="item" style="margin-top:1px">
                            范老师 010-64508600  oneplusx@shouguan.cn
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <Footer/>
    </div>
</template>
<script>

export default {
    components:{
       
    },
    data() {
        return {
            newsList:[],
            notificationList:[],
            downloadList:[],
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData(){
            this.$http.get('/api/index/getConfigList',{type:'skill_competition'}).then(res => {
                this.downloadList = res;
            })
            this.$http.get('/api/index/getNewsList',{is_index:1, type: 2}).then(res => {
                if(res[2]){
                    this.notificationList = res[2]
                }
            })
            this.$http.get('/api/index/getNewsList',{is_index:1, type: 3}).then(res => {
                if(res[3]){
                    this.newsList = res[3]
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.flex{
    display: flex;
    justify-content: space-between;
}
.l-bl{
    width:584px;
    margin-bottom: 110px;
    .bl-b{
        border: 1px solid #A0A0A0;
        padding: 13px 12px;
        box-sizing: border-box;
    }
    .no-first{
        border-top: 0px !important;
    }
    .item{
        width:100%;
        position: relative;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        // margin-top:6px;
        img{
            position: absolute;
            right: 7px;
            width: 22px;
            cursor: pointer;
        }
    }

}
.d-bl{
    margin-top: 21px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #666666;
    width: 584px;
    img{
        width:100%;
        margin-top: 27px;
    }
}
.img-block{
    width:286px;
    height:171px;
    margin-top: 35px;
    position: relative;
}
.img-txt{
    width:100%;
    text-align: center;
    font-size: 21px;
    font-family: Microsoft YaHei UI;
    font-weight: bold;
    color: #FFFFFF;
    position: absolute;
    left: 0px;
    top: 72px;
}
.imgc{
    width:286px;
    height:171px;
    
}
.img-item{
    width: 587px;
    height: 51px;
    line-height: 51px;
    font-size: 27px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
}
.content{
    width: 1200px;
    margin: auto;
}

.banner{
    width:100%;
    height: 500px;
    position: relative;
    img{
        position: absolute;
        width:1920px;
        height: 500px;
        left: calc(50% - 960px);
    }
}

.main-page{
    width: 1200px;
    margin: auto;
    .jn-title{
        width:100%;
        margin: 30px auto 35px auto;
        .top-txt{
            text-align: center;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #3A55F1;
        }
        .sub-txt{
            position: relative;
            width:363px;
            text-align: center;
            margin: 5px auto 0 auto;
            font-size: 27px;
            height: 38px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            text-align: center;
            span{
                position: absolute;
                z-index: -1;
                background: #E6E6E6;
                display: inline-block;
                width:363px;
                height:14px;
                left:0px;
                bottom:0px;
            }
        }
    }

    .desc{
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        width: 100%;
        margin-top: 27px;
        text-indent: 2em;
    }
    .more{
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #154FE9;
        text-align: center;
        margin-bottom: 40px;
    }

    .flex-list{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .item{
            margin-top: 35px;
            cursor: pointer;
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            width:63px;
            text-align: center;
            img{
                width:100%;
            }
        }
    }

    .news-block{
        width:100%;
        display: flex;
        justify-content: space-between;
        
    }
    .blo{
        width:calc(50% - 10px);
        .title{
            width:100%;
            font-size: 27px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #666666;
            position: relative;
            span{
                position: absolute;
                font-size: 19px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FF0000;
                bottom: 0px;
                right:0px;
            }
        }
        .news-item{
            padding: 18px 88px 9px 13px;
            background: #F2F2F2;
            position: relative;
            margin-top: 16px;
            .only-show-one{
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                word-break: break-all;
            }
            .time{
                font-size: 5px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #666666;
                margin-top: 13px;
            }
            .is-end{
                position: absolute;
                right: 20px;
                top: 30px;
                width: 56px;
                height: 23px;
                line-height: 23px;
                text-align: center;
                background: #B5B5B5;
                border-radius: 12px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
}



</style>
